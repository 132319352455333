import React from 'react'
import Footer from '@components/footer'
import Nav from '@components/nav'
import PrivacyPolicyDescription from './PrivacyPolicyDescription'
import Layout from '@components/layouts'

const PrivacyPolicy = () => {
  return (
    <>
      <Layout>
        <>
          <Nav />
          <PrivacyPolicyDescription />
          <Footer />
        </>
      </Layout>
    </>
  )
}

export default PrivacyPolicy
